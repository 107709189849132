export const environment = {
  production: true,
  entityId: 42,
  apiUrl: "https://app-api.imodigi.com",
  imgApi: "https://admin-api.imodigi.com",
  // apiUrl: "http://localhost:3000",
  // imgApi: "http://localhost:3000",
  dirImgProperty: "properties/image/",
  dirImgUser: "users/profile/image/",
  dirEntityImg: "entities/logo/image/",
  template: 'template1',
  mainColor: '#38C',
  secondColor: '',
  headerColor: '#38CC',
  v1Api: "https://www.imodigi.com/__app_php__/v2",
  wsUrl: "wss://www.imodigi.com/chat/",
  subDir: "",
  logoType: "",
  otherLogo: ""
};
