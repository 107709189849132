import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchPageHomeComponent } from './search-page-home/search-page-home.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { AdvancedSearchHomeComponent } from './advanced-search-home/advanced-search-home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [
    SearchPageHomeComponent,
    AdvancedSearchHomeComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    IonicModule,
    TranslateModule,
    MatInputModule,
    NgbModule
  ],
  exports: [
    SearchPageHomeComponent
  ]
})
export class SearchModule { }
